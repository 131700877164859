import { useState, useEffect } from "react";
import Modal from "./components/Modal"; // Import the modal component
import "./Detail.css"; // Import CSS for styling
import Header from "./components/Header";
import axios from "axios";
import { toast } from "react-toastify";
import eventsData from "./data/events.json"; // Import the events data
import glData from "./data/gl.json"; // Import the GL data

function Detail() {
	const [loading, setLoading] = useState(true);
	const [saData, setSaData] = useState(null);

	const [searchTerm, setSearchTerm] = useState("");
	const [searchCriteria, setSearchCriteria] = useState("name");
	const [paymentFilter, setPaymentFilter] = useState("all");

	const [selectedStudent, setSelectedStudent] = useState(null);

	useEffect(() => {
		if (!localStorage.getItem("nssc-sa-token")) {
			window.location.replace("/login");
		}

		axios
			.get(
				`${process.env.REACT_APP_API_URL}/ambassador/getParticipants`,
				{
					headers: {
						Authorization: localStorage.getItem("nssc-sa-token"),
					},
				}
			)
			.then((res) => {
				if (res.data.status === 200) {
					res.data.participants.sort((a, b) => {
						if (a.fName + " " + a.lName < b.fName + " " + b.lName)
							return -1;
						return 1;
					});
					setSaData(res.data);
				} else {
					toast.error(res.data.message);
				}
				setLoading(false);
			})
			.catch((err) => {
				// console.error(err);
				toast.error("An error occurred. Please try again later.");
			});
	}, []);

	const closeModal = () => {
		setSelectedStudent(null);
	};

	const participantFilter = (filtered) => {
		if (!saData) return [];

		const term = searchTerm.toLowerCase();

		if (term === "") {
			if (searchCriteria === "event") {
				filtered = filtered.filter((student) => {
					return Object.keys(eventsData).some((key) => {
						let event =
							key <= 3
								? student.events[key].status
								: student.events[key];
						return event;
					});
				});
			}
			if (searchCriteria === "gl") {
				filtered = filtered.filter((student) => {
					return Object.keys(glData).some((key) => {
						return student.gl[key];
					});
				});
			}
		} else {
			filtered = filtered.filter((student) => {
				switch (searchCriteria) {
					case "name":
						return (
							student.fName.toLowerCase().includes(term) ||
							student.lName.toLowerCase().includes(term)
						);
					case "pid":
						return student.pid.toLowerCase().includes(term);
					case "phone":
						return student.phone.toLowerCase().includes(term);
					case "roll":
						return student.roll.toLowerCase().includes(term);
					case "hall":
						return (
							student.hall &&
							student.hall.toLowerCase().includes(term)
						);
					case "event":
						return Object.keys(eventsData).some((key) => {
							let event =
								key <= 3
									? student.events[key].status
									: student.events[key];
							return (
								event &&
								eventsData[key].name
									.toLowerCase()
									.includes(term)
							);
						});
					case "gl":
						return Object.keys(glData).some((key) => {
							return (
								student.gl[key] &&
								glData[key].guestName
									.toLowerCase()
									.includes(term)
							);
						});
					default:
						return false;
				}
			});
		}

		if (paymentFilter !== "all") {
			filtered = filtered.filter(
				(student) =>
					student.payment.status == (paymentFilter === "paid")
			);
		}

		return filtered;
	};

	if (!saData || loading) {
		return <div>Loading...</div>;
	}

	const filteredStudents = participantFilter(saData.participants);

	return (
		<>
			<Header details={true} />
			<div className="main-container">
				<h1 style={{ textAlign: "center" }}>
					Student Ambassador Details
				</h1>

				<div className="students-container">
					<div className="student-card sa-card">
						<div className="student-header">
							<h2 className="student-name">
								{saData.saDetails.fName +
									" " +
									saData.saDetails.lName}
							</h2>
						</div>
						<div className="student-body">
							<p>
								<strong>Phone Number:</strong>{" "}
								{saData.saDetails.phone}
							</p>
							<p>
								<strong>Email:</strong> {saData.saDetails.email}
							</p>
							<p>
								<strong>SA-ID:</strong> {saData.saDetails.saID}
							</p>
							<p>
								<strong>Total Participants:</strong>{" "}
								{saData.saDetails.pids.length}
							</p>
							<p>
								<strong>Total Paid Participants:</strong>{" "}
								{
									saData.participants.filter(
										(student) => student.payment.status
									).length
								}
							</p>
						</div>
					</div>
				</div>
				<div className="students-container">
					<div className="search-container">
						<select
							style={{ fontSize: "20px", marginRight: "10px" }}
							value={searchCriteria}
							onChange={(e) => setSearchCriteria(e.target.value)}
						>
							<option value="name">Name</option>
							<option value="pid">PID</option>
							<option value="phone">Phone Number</option>
							<option value="roll">Roll Number</option>
							<option value="hall">Hall</option>
							<option value="event">Event Participated</option>
							<option value="gl">GL Participated</option>
						</select>
						<input
							type="text"
							id="search-input"
							placeholder="Search..."
							style={{ fontSize: "20px", marginRight: "10px" }}
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
						<select
							style={{ fontSize: "20px", marginRight: "10px" }}
							value={paymentFilter}
							onChange={(e) => setPaymentFilter(e.target.value)}
						>
							<option value="all">All</option>
							<option value="paid">Paid</option>
							<option value="unpaid">Unpaid</option>
						</select>
					</div>
				</div>
				<h1 style={{ textAlign: "center" }}>Participants</h1>
				{filteredStudents.length && (
					<div style={{ textAlign: "center", fontSize: "1.2rem" }}>
						{filteredStudents.length} Participants
					</div>
				)}

				<div className="students-container">
					{filteredStudents.map((student, index) => (
						<div key={index} className="student-card">
							<div className="student-header">
								<h2 className="student-name">
									{student.fName + " " + student.lName}
								</h2>
								<span
									className={`student-status ${
										student.payment.status
											? "paid"
											: "unpaid"
									}`}
								>
									{student.payment.status ? "Paid" : "Unpaid"}
								</span>
							</div>
							<div className="student-body">
								<p>
									<strong>Phone Number:</strong>{" "}
									{student.phone}
								</p>
								<p>
									<strong>Roll Number:</strong> {student.roll}
								</p>
								<p>
									<strong>PID:</strong> {student.pid}
								</p>
								<p>
									<strong>Hall:</strong>{" "}
									{student.hall
										? student.hall
										: "Not provided yet"}
								</p>
								<button
									onClick={() => {
										setSelectedStudent(student);
									}}
									className="shadow__btn"
								>
									Participation
								</button>
							</div>
						</div>
					))}

					{filteredStudents.length == 0 && (
						<div className="no-students">No Participants found</div>
					)}

					{selectedStudent && (
						<Modal student={selectedStudent} onClose={closeModal} />
					)}
				</div>
			</div>
		</>
	);
}

export default Detail;
