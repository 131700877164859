import { useEffect, useState } from "react";
import "./Login.css";
import axios from "axios";
import { toast } from "react-toastify";
import Header from "./components/Header";

function Login() {
	const [saId, setSaId] = useState("");
	const [password, setPassword] = useState("");

	useEffect(() => {
		if (localStorage.getItem("nssc-sa-token")) {
			window.location.replace("/details");
		}
	}, []);

	// Handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		if (!saId || !password) {
			toast.error("Please fill all the fields");
			return;
		}
		axios
			.post(`${process.env.REACT_APP_API_URL}/ambassador/login`, {
				saID: saId,
				password: password,
			})
			.then((res) => {
				if (res.data.status == 200) {
					toast.success(res.data.message);
					localStorage.setItem("nssc-sa-token", res.data.token);
					return window.location.replace("/details");
				} else {
					toast.error(res.data.message);
				}
			})
			.catch((err) => {
				// console.error(err);
				toast.error("An error occurred. Please try again later.");
			});
	};

	return (
		<>
			<Header />
			<div className="login_container">
				<div className="login_card">
					<h2>Student Ambassador Login</h2>
					<form onSubmit={handleSubmit}>
						<input
							type="text"
							id="sa-id"
							name="sa-id"
							placeholder="SA-ID"
							value={saId}
							onChange={(e) => setSaId(e.target.value)}
							required
						/>
						<input
							type="password"
							id="password"
							name="password"
							placeholder="Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
						/>

						<button className="login_buttonsa" type="submit">
							Login
						</button>
					</form>
				</div>
			</div>
		</>
	);
}

export default Login;
