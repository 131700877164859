import "./Header.css";

const Header = ({ details = false }) => {
	return (
		<header id="header" className="fixed-top d-flex align-items-center">
			<div className="container-fluid">
				<div className="row justify-content-center align-items-center">
					<div className="col-xl-11 d-flex align-items-center justify-content-between">
						<h1
							className="logo"
							style={{ borderLeft: "4px solid purple" }}
						>
							<a
								href="/"
								style={{
									fontSize: "24px",
									textDecoration: "None",
								}}
							>
								NSSC | IIT Kharagpur{" "}
							</a>
						</h1>
						<nav id="navbar" className="navbar">
							<ul>
								<li>
									<a
										className="nav-link scrollto active"
										style={{ fontSize: "20px" }}
										href="/#about"
									>
										About us
									</a>
								</li>
								<li>
									<a
										className="nav-link scrollto active"
										style={{ fontSize: "20px" }}
										href="/#form"
									>
										Register
									</a>
								</li>
								{details ? (
									<li>
										<a
											className="nav-link scrollto active"
											style={{
												fontSize: "20px",
												color: "red",
												cursor: "pointer",
											}}
											href="/"
											onClick={(e) => {
												e.preventDefault();
												localStorage.removeItem(
													"nssc-sa-token"
												);
												window.location.reload();
											}}
										>
											Log Out
										</a>
									</li>
								) : (
									<li>
										<a
											className="nav-link scrollto active"
											style={{ fontSize: "20px" }}
											href="/login"
										>
											Login
										</a>
									</li>
								)}
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
