import Header from "./components/Header";
import Carousel from "./components/Carousal";
import AboutSection from "./components/About";
import FactSection from "./components/Fact";
import ClientsSection from "./components/Sponsors";
import RegisterForm from "./components/RegisterForm";

export default function Home() {
	return (
		<>
			<Header />
			<Carousel />
			<AboutSection />
			<FactSection />
			<ClientsSection />
			<RegisterForm />
		</>
	);
}
